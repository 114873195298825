import {TreeNode} from "@/components/tree/Nodes/TreeNode";
import _ from "lodash";
import {boAxios} from "@/network";
import {TeacherNode} from "@/components/tree/Nodes/TeacherNode";
import {StudentListNode} from "@/components/tree/Nodes/StudentListNode";
import {CLASS_NODE_TYPE, TEACHER_NODE_TYPE} from "@/components/tree/Nodes/user_tree_node_types";
import {TeacherListNode} from "@/components/tree/Nodes/TeacherListNode";

export class ClassNode extends TreeNode {

    alreadyFetched=false
    class=null

    constructor(clazz, parentNode=null) {
        super(clazz.id, `${clazz.name} (${clazz.class_code})`, CLASS_NODE_TYPE, parentNode);
        this.class = clazz
        this.children = []
        this.icon = 'mdi-google-classroom'
        this._initChildren();
    }

    _initChildren() {
        if (this.class.teachers && this.class.students) {
            this.children = [new TeacherListNode(this), new StudentListNode(this)]
        }
    }

    async fetchChildren() {
        if (!this.alreadyFetched) {
            if (_.isEmpty(this.class.students)) {
                const {data, status} = await boAxios.get(`/class/${this.id}`)
                if (status !== 200) {
                    return;
                }
                _.assign(this.class, data)
                this.alreadyFetched=true;
            }
            this.children = []
            if (this.getGrandParentType() !== TEACHER_NODE_TYPE) this.children.push(new TeacherNode(this.class.teacher, this))
            this.children.push(new StudentListNode(this))
        }
        return this.children;
    }

    getObject() {
        return this.children.map(node => node.getObject())
    }

    refresh() {
        super.refresh();
        this.alreadyFetched = false;
        this.fetchChildren()
    }

}

