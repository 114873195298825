<template>
  <v-card>
    <UserSelectDialog name="teacher_select" ref="userSelectDialog"/>
    <DeleteDialog ref="treeDeleteDialog" v-on:delete-dialog-confirmation="deleteCurrentItem($event)"></DeleteDialog>
    <ClassEditorDialog name="tree_class" ref="classEditorDialog" v-on:delete-dialog-confirmation="deleteCurrentItem($event)"></ClassEditorDialog>
    <EditorDialog name="tree" ref="treeEditDialog" :on-delete="showDeleteDialog"></EditorDialog>
    <v-card-title class="indigo white--text headline">
      Related Entities
    </v-card-title>
    <v-row
        class="pa-4"
        justify="space-between"
    >
      <v-col cols="8">
        <v-treeview
            :active.sync="active"
            :items="items"
            :load-children="fetchItems"
            :open.sync="open"
            activatable
            transition
        >
          <template v-slot:label="{item, active, open}">
            <span v-if="active && !item.type.endsWith('list')">
              <v-btn color="blue" text @click.prevent="edit(item)">
                              <v-icon class="mr-2" color="blue">
              {{item.icon}}
              </v-icon>
                {{item.name}}
                <v-icon small class="ml-2">mdi-pencil</v-icon>
                EDIT
              </v-btn>
              <v-btn v-if="(item.type==='teacher' && root.type !== 'school_account') || item.type==='student'" color="blue" text @click.prevent="stopTracking(item)">
                <v-icon small class="mr-2" color="blue">
                    mdi-account-remove
                </v-icon>
                Stop Tracking
              </v-btn>
              <v-btn v-if="(item.type==='teacher' && root.type !== 'school_account') || item.type==='student'" color="blue" text @click.prevent="add_to_bulk(item)">
                <v-icon small class="mr-2" color="blue">
                   mdi-plus
                </v-icon>
                Add To Bulk
              </v-btn>
              <v-btn v-if="item.type==='teacher' && root.type === 'school_account'" color="blue" text @click.prevent="removeSchoolAccount(item)">
                <v-icon small class="mr-2" color="blue">
                    mdi-account-remove
                </v-icon>
                Remove From School
              </v-btn>
              <v-btn v-if="item.type==='class'" color="blue" text @click.prevent="connect_classes(item)">
                <v-icon small class="mr-2" color="blue">
                  mdi-content-copy
                </v-icon>
                Add Co-Teacher
              </v-btn>
              <v-btn v-if="item.type==='class'" color="blue" text @click.prevent="add_bulk_students_to_class(item)">
                <v-icon small class="mr-2" color="blue">
                  mdi-plus
                </v-icon>
                Import students from bulk
              </v-btn>
            </span>
            <span v-else-if="open && isStudentListNode(item) && item.hasChildren()">
              <v-icon class="mr-2">
              {{item.icon}}
              </v-icon>{{item.name}}
              <v-btn color="blue" text @click.prevent="add_to_bulk(item)">
                <v-icon small class="mr-2" color="blue">
                   mdi-plus
                </v-icon>
                Add To Bulk
              </v-btn>
            </span>
            <span v-else-if="open && isClassListNode(item) && item.hasChildren()">
              <v-icon class="mr-2">
              {{item.icon}}
              </v-icon>{{item.name}}
              <v-btn color="blue" text @click.prevent="connect_classes(item)">
                <v-icon small class="mr-2" color="blue">
                    mdi-content-copy
                </v-icon>
                Connect All Classes to another teacher
              </v-btn>
            </span>
            <span v-else>
              <v-icon class="mr-2">
              {{item.icon}}
              </v-icon>{{item.name}}</span>
          </template>
        </v-treeview>
      </v-col>
      <v-col cols="3">
        <UserBulkActions></UserBulkActions>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import {TeacherNode} from "@/components/tree/Nodes/TeacherNode";
import {TeacherListNode} from "@/components/tree/Nodes/TeacherListNode";
import {ClassNode} from "@/components/tree/Nodes/ClassNode";
import {StudentNode} from "@/components/tree/Nodes/StudentNode";
import {SchoolAccountNode} from "@/components/tree/Nodes/SchoolAccountNode";
import _ from "lodash";
import {
  CLASS_LIST_NODE_TYPE,
  CLASS_NODE_TYPE,
  STUDENT_LIST_NODE_TYPE,
  STUDENT_NODE_TYPE,
  TEACHER_NODE_TYPE,
  SCHOOL_ACCOUNT_NODE_TYPE
} from "@/components/tree/Nodes/user_tree_node_types";
import {addStudentToClass, removeSchoolAccountFromUser} from '@/api/api'
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import UserBulkActions from "@/components/Users/UserBulkActions.vue";
import {EventBus, NOTIFY_EVENT} from "@/eventbus";

export default {
  name: 'UserTree',
  components: {
    UserBulkActions,
    ClassEditorDialog: () => import('../ClassEditorDialog'),
    DeleteDialog: () => import('../DeleteDialog'),
    EditorDialog: () => import('../UserEditorDialog'),
    UserSelectDialog: () => import('../UserSelectDialog')
  },
  props: ['rootObject'],
  data: () => ({
    show: true,
    active: [],
    avatar: null,
    open: [],
    users: [],
    classes: [],
    teachers: [],
    students: [],
    root: null,

  }),

  watch: {
    rootObject: function(newVal) {
      if (newVal) {
        this.initRootNode(newVal)
      }
    }
  },
  computed: {
    ...mapState({
      bulk_users: state => state.bulk_actions.users,
    }),
    ...mapGetters(['bulk_actions'], ['bulkStudents']),
    items () {
      if (this.root) return this.root.children
      return []
    },
    selected () {
      if (!this.active.length) return undefined

      const id = this.active[0]

      return this.users.find(user => user.id === id)
    },
  },
  mounted() {
    const newVal = this.rootObject;
    console.log(`RootNode is: `,newVal)
    if (newVal) {
      this.initRootNode(newVal)
    }
  },

  // watch: {
  //   // selected: 'randomAvatar',
  // },

  methods: {
    ...mapActions(['setGlobalLoading']),
    initRootNode(nodeItem) {
      if (nodeItem.type === CLASS_NODE_TYPE) {
        this.root = new ClassNode(nodeItem);
      } else if (nodeItem.type === STUDENT_NODE_TYPE) {
        this.root = new StudentNode(nodeItem)
      } else if (nodeItem.type === TEACHER_NODE_TYPE) {
        this.root = new TeacherNode(nodeItem)
      } else if (nodeItem.type === SCHOOL_ACCOUNT_NODE_TYPE) {
        this.root = new SchoolAccountNode(nodeItem)
      } else {
        console.error('Unknown object type as root for UserTree')
      }
    },
    isStudentListNode(node) {
      return STUDENT_LIST_NODE_TYPE === node.type
    },
    isClassListNode(node) {
      return CLASS_LIST_NODE_TYPE === node.type
    },
    ...mapMutations(['ADD_USERS_FOR_BULK_ACTIONS']),
    async edit(item) {
      if (item.type === 'class') {
        let id = item.id;
        if (id) {
          let url = `/class/${id}`;
          const {data: clazz, status} = await this.axios(url);
          if (status !== 200) {
            this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
            return;
          }
          if (clazz.id) {
            clazz.type = item.type
            clazz.class_id = clazz.id
            await this.$refs.classEditorDialog.show(clazz)
          } else {
            this.$root.$emit('notify', {message: `Server: no class`, type: 'error'})
          }
        }
        return;
      }
      let id = null
      if (item.type === 'student') {
        id = item.student.user_id;
      } else if (item.type === 'teacher') {
        id = item.teacher.user_id;
      }
      if (id) {
        let url = `/user/${id}`;
        const {data, status} = await this.axios(url);
        if (status !== 200) {
          this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          return;
        }
        if (data.success === true || data.success === 'true') {
          const user = _.head(data.items)
          user.type = item.type
          user.user_id = user.id
          try {
            user.user_settings = JSON.parse(user.user_settings);
            if (typeof user.user_settings === 'string' || user.user_settings instanceof String) {
              user.user_settings = JSON.parse(user.user_settings);
            }
          } catch(e) {
            user.user_settings = {}
          }

          this.openItem(user)
        } else {
          this.$root.$emit('notify', {message: `Server: ${data.message}`, type: 'error'})
        }
      }
    },
    openItem(item) {
      this.$refs.treeEditDialog.show(item);
    },
    async add_to_bulk(item) {
      if (item.type === STUDENT_LIST_NODE_TYPE) {
        this.ADD_USERS_FOR_BULK_ACTIONS(item.getObject())
      } else {
        this.ADD_USERS_FOR_BULK_ACTIONS([item.getObject()]);
      }
    },
    async add_bulk_students_to_class(item) {
        this.setGlobalLoading(true);
        try {
          const classId = item.class.id
          //get all student ids from BO api
          const userIdsToAddToClass = this.bulk_users.filter(user => user.student_id || user.roles[0]===3).map(user => user.user_id)
          let res = await this.axios.get(`/user/student?ids=${userIdsToAddToClass.join(',')}`)
          const studentIds = res.data.map(id => Number(id))

          //add all students to teacher and class
          res = await this.axios.get(`/class/${classId}`)

          const existingStudentIds = res.data.students.map(student => student.id)
          const studentIdsToAdd = _.filter(studentIds, id => !existingStudentIds.includes(id))
          console.log(`adding these students: ${studentIdsToAdd}`)

          if (studentIdsToAdd.length < 1) {
            this.notify('All these students are already in this class' , 'info')
          } else {
            this.notify(`Adding ${studentIdsToAdd.length} students to class ${item.class.name}`, 'info')
            let count = 0;
            for(let i=0; i < studentIdsToAdd.length; i++) {
              try {
                const currStudentId = studentIdsToAdd[i]
                console.log(`Adding student ${currStudentId} to class ${classId}...`)
                await addStudentToClass(currStudentId, classId)
                count++;
              }
              catch (e) {
                console.log(e)
              }
            }
            let msg = `Successfully Added ${count} students to class ${item.class.name}`
            const success = count === studentIdsToAdd.length
            if (!success) {
              msg = `Something is wrong, added only ${count} students out of ${studentIdsToAdd.length} to the class.`
            }
            this.notify(msg , success ? 'success' : 'warning')
          }
        }
        catch (e) {
          console.log(e)
        }
        finally {
          item.refresh()
          this.setGlobalLoading(false);
        }

    },
    notify(message, type) {
      EventBus.$emit(NOTIFY_EVENT, {message, type})
    },

    async connect_classes(item) {
      const class_ids = item.type === CLASS_LIST_NODE_TYPE ? item.children.map(node => node.id) : [item.id]
      const target_teacher_user = await this.$refs.userSelectDialog.show();
      this.setGlobalLoading(true);
      const {data} = await this.axios.get(`/user/${target_teacher_user.id}/teacher`)
      if (data.items.length > 0) {
        const target_teacher = data.items[0];
        target_teacher.type = 'teacher';
        target_teacher.user_id = target_teacher_user.id
        if (target_teacher && target_teacher.id) {
          for (let i = 0; i < class_ids.length; i++) {
            const class_id = class_ids[i];
            let url = `/class/${class_id}/teacher/${target_teacher.id}`
            const {data, status} = await this.axios.put(url);
            if (status !== 200) {
              this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
            } else {
              this.$root.$emit('notify', {
                message: `Class connected successfully to teacher ${target_teacher_user.username}`,
                type: 'success'
              })
            }
          }
        }
      }
      this.setGlobalLoading(false);
    },

    async fetchItems (item) {
      if (item.fetchChildren)
        return item.fetchChildren()
      //
      // return fetch('https://jsonplaceholder.typicode.com/users')
      //     .then(res => res.json())
      //     .then(json => (item.children.push(...json)))
      //     .catch(err => console.warn(err))
    },

    showDeleteDialog(item) {
      this.$refs.treeDeleteDialog.show(item.username, item);
    },

    async deleteCurrentItem(item) {
      this.progress = true;
      let url = `/user/delete?id=${item.id}`;
      if (item.type==='class') {
        url = `/class/delete?id=${item.id}`;
      }
      const {data, status} = await this.axios(url);
      if (status !== 200) {
        this.progress = false;
        this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
        return;
      }
      if (data.success === true || data.success === 'true') {
        this.progress = false;
        this.$root.$emit('notify', {message: `User ${item.username} deleted.`, type: 'success'})
      } else {
        this.progress = false;
        this.$root.$emit('notify', {message: `Server: ${data.message}`, type: 'error'})
      }
    },

    async stopTracking(item) {
      let student_id = null, teacher_id = null, student_name = '', teacher_name = '', id_to_remove = null;
      if (item.type === STUDENT_NODE_TYPE) {
        const teacherNode = item.getGrandParent()
        console.log(teacherNode)
        if (teacherNode) {
          student_id = item.id
          teacher_id = teacherNode.id
          student_name = item.name;
          teacher_name = teacherNode.name;
          id_to_remove = student_id
        }
      } else if (item.type === TEACHER_NODE_TYPE) {
        const studentNode = item.getGrandParent()
        console.log(studentNode)
        if (studentNode) {
          teacher_id = item.id
          student_id = studentNode.id
          teacher_name = item.name;
          student_name = studentNode.name;
          id_to_remove = teacher_id
        }
      }
      const confirmed = window.confirm(`You are about stop tracking student ${student_name} by teacher ${teacher_name}. this will remove the student completely from this teacher and all that teacher's classes`)
      if (confirmed) {
        let url = `/teacher/${teacher_id}/students/${student_id}`
        const bk_icon = item.icon;
        item.icon = 'mdi-timer-sand';
        const {status} =  await this.axios.delete(url);
        if (status == 200) {
          console.log('student removed tracking')
          item.getParent().removeChildById(id_to_remove)
        }
        item.icon = bk_icon
      }
      //   let url = `/teacher/${teacher_id}/students/${student_id}`
      //   const {status} =  await this.axios.delete(url);
      //   if (status == 200) {
      //     this.editItem.students = this.editItem.students.filter(item => student.id !== item.id)
      //   }
      //
      // }

    },
    async deleteUserSubscription(item) {
      let url = '/user/' + item.user_id
      const user_settings = item.user_settings ? JSON.parse(item.user_settings) : {}
      user_settings.subscription = null
      const payload = {
        id: item.user_id
      }
      payload.user_settings = JSON.stringify(user_settings);
      const {status} = await this.axios.post(url, payload)
      return status
    },
    async removeSchoolAccount(item) {
      let {status} = await removeSchoolAccountFromUser(item.teacher.user_id)
      if (status === 201) {
        status = await this.deleteUserSubscription(item.teacher)
        if (status === 200) {
          const teacherListNode = this.root.children[0]
          teacherListNode.removeChildById(item.id)
        }
      }
    }
  },
}
</script>
