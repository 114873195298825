import {TreeNode} from "@/components/tree/Nodes/TreeNode";
import {ClassListNode} from "@/components/tree/Nodes/ClassListNode";
import {TeacherListNode} from "@/components/tree/Nodes/TeacherListNode";
import {STUDENT_NODE_TYPE} from "@/components/tree/Nodes/user_tree_node_types";
import {ROLE_STUDENT} from "@/constants";

export class StudentNode extends TreeNode {

    constructor(user, parentNode=null) {
        super(user.id, user.username, STUDENT_NODE_TYPE, parentNode);
        this.student = user
        this._initChildren()
    }

    get name() {
        return `${this.student.first_name} ${this.student.last_name} (${this.student.username})`
    }

    _initChildren() {
        this.children = [new TeacherListNode(this), new ClassListNode(this)]
    }

    getObject() {
        return {
            ...this.student,
            id: this.student.user_id,
            student_id: this.student.id
        };
    }



}
