import {TreeNode} from "@/components/tree/Nodes/TreeNode";
import {ClassListNode} from "@/components/tree/Nodes/ClassListNode";
import {StudentListNode} from "@/components/tree/Nodes/StudentListNode";
import {TEACHER_NODE_TYPE} from "@/components/tree/Nodes/user_tree_node_types";
import {ROLE_TEACHER} from "@/constants";

export class TeacherNode extends TreeNode {

    constructor(user, parentNode) {
        super(user.id, user.username, TEACHER_NODE_TYPE, parentNode)
        this.teacher = user;
        this.teacher.roles = [ROLE_TEACHER]
        this.icon='mdi-account-star'
        this._initChildren()
    }

    get name() {
        return `${this.teacher.first_name} ${this.teacher.last_name} (${this.teacher.username})`
    }

    _initChildren() {
        this.children = [new ClassListNode(this), new StudentListNode(this)]
    }

    getObject() {
        return {
            ...this.teacher,
            id: this.teacher.user_id,
            teacher_id: this.teacher.id
        };
    }
}
