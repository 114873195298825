<template>
  <v-row>
    <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <div>
        <v-card elevation="2">
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Edit {{users.length}} Users</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                  dark
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-subheader>User Profile
          </v-subheader>
          <v-form class="pa-5">
            <v-row class="left">
              <v-col cols="12"
                     sm="12"
                     md="3">
                <v-combobox
                    v-model="model.timezone"
                    label="Timezone"
                    :items="timezones"
                >
                </v-combobox>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
</template>
<script>
import {EventBus, NOTIFY_EVENT} from "@/eventbus";
import {TIMEZONES} from "@/constants";
import {mapActions, mapState} from "vuex";

export default {
  name: 'UserBulkEditorDialog',
  data() {
    return {
      dialog: false,
      users: [],
      model: {
        timezone: null
      }
    }
  },
  props: {
    onClose: {
      type: Function,
      default: () => {
      },
    }
  },
  watch: {
    dialog: function (newVal) {
      if (!newVal) {
        this.resetUI()
        this.onClose()
      }
    }
  },
  computed: {
    ...mapState(['user', 'groups']),
    timezones() {
      return TIMEZONES.map(tz => tz.zone_id)
    },
    isCognitoAdmin() {
      return this.groups.indexOf('admin') > -1
    }
  },
  methods: {
    ...mapActions(['setGlobalLoading']),
    notify(message, type) {
      EventBus.$emit(NOTIFY_EVENT, {message, type})
    },
    async show(users) {
      this.dialog = true;
      this.users = users;
    },
    resetUI() {
      this.model.timezone = null;
      this.users = [];
    },
    async save() {
      let successAll = true;
      this.setGlobalLoading(true);
      for (let i=0; i< this.users.length; i++) {
        const currUser = this.users[i];
        let url = '/user/' + currUser.id
        const payload = {'timezone': this.model.timezone}
        const {data, status} = await this.axios.post(url, payload)
        if (status !== 200) {
          this.notify(`Error: server returned status: ${status}`, 'error')
        }
        successAll = successAll && data.success;
        if (data.success !== true) {
          this.notify(data.message, 'error')
        }
      }
      this.setGlobalLoading(false);
      this.dialog = false;
      if (successAll) this.$root.$emit('notify', {'message': `Changes saved for ${this.users.length} users.`, type: 'success'})
      this.resetUI();
    }
  }

}
</script>
