export const setAuthToken = (token) => {
    sessionStorage.setItem('token', token)
}

export const getAuthToken = () => {
    // return sessionStorage.getItem('token')
    // for production
    return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTkzMjkyMzgsInJvbGVzIjpbIlJPTEVfVVNFUiIsIlJPTEVfQURNSU4iXSwidGltZXN0YW1wIjoxNzAyNTkzNDIxMjc5LCJpYXQiOjE3MDI1OTM0MjF9.Aqr-uwjAwQou5Bc9X4yTSP7Nk68yQhV-eu7_5SOVhe4'
    // for dev
    // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDksInJvbGVzIjpbIlJPTEVfVVNFUiIsIlJPTEVfQURNSU4iLCJST0xFX1RFQUNIRVIiXSwidGltZXN0YW1wIjoxNjg3MzcyMDk0ODg2LCJpYXQiOjE2ODczNzIwOTR9.WWEy0FNbQgAnSc5No2mJMMR-KrBNwd5NKUgo_G036Iw'
}
