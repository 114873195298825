import {TreeNode} from "@/components/tree/Nodes/TreeNode";
import _ from "lodash";
import {boAxios} from "@/network";
import {TeacherNode} from "@/components/tree/Nodes/TeacherNode";
import {CLASS_NODE_TYPE, STUDENT_NODE_TYPE, SCHOOL_ACCOUNT_NODE_TYPE, TEACHER_LIST_NODE_TYPE} from "@/components/tree/Nodes/user_tree_node_types";
import {getConnectedTeachersForAccount} from '@/api/api'

export class TeacherListNode extends TreeNode {

    alreadyFetched = false;

    constructor(parent = null, children = []) {
        super(_.uniqueId('TeacherListNode'), 'Teachers', TEACHER_LIST_NODE_TYPE, parent)
        this.children = children
        this.icon='mdi-account-star'
    }

    async fetchChildren() {
        if (!this.alreadyFetched) {
            if (this.parentNode.type === STUDENT_NODE_TYPE) {
                if (_.isEmpty(this.parentNode.student.teachers)) {
                    const {data, status} = await boAxios.get(`/student/${this.parentNode.student.id}/teachers/list`)
                    if (status !== 200) {
                        return;
                    }
                    this.parentNode.student.teachers = data.items
                    this.alreadyFetched=true;
                }
                this.parentNode.student.teachers.forEach(teacher => {
                    this.children.push(new TeacherNode(teacher, this))
                })
                if (_.isEmpty(this.parentNode.student.teachers)) {
                    this.children = undefined;
                    this.name = 'No Teachers'
                    this.icon='mdi-account-star-outline'
                }
            } else if (this.parentNode.type === CLASS_NODE_TYPE) {
                if (_.isEmpty(this.parentNode.class.teachers)) {
                    const {data, status} = await boAxios.get(`/class/${this.parentNode.class.id}/teachers/list`)
                    if (status !== 200) {
                        return;
                    }
                    this.parentNode.class.teachers = data.items
                    this.alreadyFetched=true;
                }
                this.parentNode.class.teachers.forEach(teacher => {
                    this.children.push(new TeacherNode(teacher, this))
                })
                if (_.isEmpty(this.parentNode.class.teachers)) {
                    this.children = undefined;
                    this.name = 'No Teachers'
                    this.icon='mdi-account-star-outline'
                }
            } else if (this.parentNode.type === SCHOOL_ACCOUNT_NODE_TYPE) {
                if (_.isEmpty(this.parentNode.school_account.teachers)) {
                    try {
                        const {data} = await getConnectedTeachersForAccount(this.parentNode.school_account.id)
                        this.parentNode.school_account.teachers = data?.items || []
                    } catch (e) {
                        console.log(e)
                    }
                    this.alreadyFetched=true;
                }
                this.parentNode.school_account.teachers.forEach(teacher => {
                    this.children.push(new TeacherNode(teacher, this))
                })
                if (_.isEmpty(this.parentNode.school_account.teachers)) {
                    this.children = undefined;
                    this.name = 'No Teachers'
                    this.icon='mdi-account-star-outline'
                }
            }
        }

        return this.children;
    }

    removeChildById(id) {
        this.children = this.children.filter(teacherNode => teacherNode.id !== id);
        if (this.parentNode.type === STUDENT_NODE_TYPE && !_.isEmpty(this.parentNode.student.teachers)) {
            this.parentNode.student.teachers = this.parentNode.student.teachers.filter(teacher => teacher.id !== id)
        }
        if (this.parentNode.type === CLASS_NODE_TYPE && !_.isEmpty(this.parentNode.class.teachers)) {
            this.parentNode.class.teachers = this.parentNode.class.teachers.filter(teacher => teacher.id !== id)
        }
    }

    getObject() {
        return this.children.map(node => node.getObject())
    }

    refresh() {
        super.refresh();
        this.alreadyFetched = false;
        this.fetchChildren()
    }

}
