import _ from 'lodash'

export class TreeNode {

    icon = 'mdi-account'
    id
    _name
    type
    children
    parentNode

    constructor(id, name, type, parent=null) {
        this.id = id;
        this._name = name;
        this.type = type;
        this.parentNode = parent
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get children() {
        return this.children
    }

    set children(children) {
        this.children = children;
    }

    isLeaf() {
        return !this.hasChildren()
    }

    hasChildren() {
        return (this.children && this.children.length > 0);
    }

    fetchChildren() {
        this.children = null;
    }

    getParentType() {
        return _.result(this, 'parentNode.type', null)
    }

    getGrandParentType() {
        return _.result(this, 'parentNode.parentNode.type', null)
    }

    getParent() {
        return _.result(this, 'parentNode', null)
    }

    getGrandParent() {
        return _.result(this, 'parentNode.parentNode', null)
    }

    removeChildById(id) {

    }

    getObject () {
        return null;
    }

    refresh() {

    }

}

