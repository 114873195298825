import {TreeNode} from "@/components/tree/Nodes/TreeNode";
import _ from "lodash";
import {StudentNode} from "@/components/tree/Nodes/StudentNode";
import {boAxios} from "@/network";
import {STUDENT_LIST_NODE_TYPE} from "@/components/tree/Nodes/user_tree_node_types";

export class StudentListNode extends TreeNode {

    constructor(parentNode = null, children = []) {
        super(_.uniqueId('StudentListNode'), 'Students', STUDENT_LIST_NODE_TYPE, parentNode)
        this.children = children
        this.icon='mdi-account-group'
    }

    async fetchChildren() {
        if (!this.alreadyFetched) {
            const nodeType = this.parentNode.type;
            const parentObj = this.parentNode[nodeType];
            if (parentObj && _.isEmpty(parentObj.students)) {
                const {data, status} = await boAxios.get(`/${nodeType}/${parentObj.id}/students/list`)
                if (status !== 200) {
                    return;
                }
                parentObj.students = data.items
                this.alreadyFetched=true;
            }
            parentObj.students.forEach(student => {
                this.children.push(new StudentNode(student, this))
            })
            if (_.isEmpty(parentObj.students)) {
                this.children = undefined;
                this.name = 'No Students'
                this.icon = 'mdi-account-group-outline'
            }
        }
        return this.children;
    }

    removeChildById(id) {
        this.children = this.children.filter(studentNode => studentNode.id !== id);
        const nodeType = this.parentNode.type;
        const parentObj = this.parentNode[nodeType];
        if (parentObj && !_.isEmpty(parentObj.students)) {
            parentObj.students = parentObj.students.filter(student => student.id !== id)
        }
    }

    getObject() {
        return this.children.map(node => node.getObject())
    }

    refresh() {
        super.refresh();
        this.alreadyFetched = false;
        this.fetchChildren()
    }

}
