import {TreeNode} from "@/components/tree/Nodes/TreeNode";
import _ from "lodash";
import {ClassNode} from "@/components/tree/Nodes/ClassNode";
import {boAxios} from "@/network";
import {CLASS_LIST_NODE_TYPE} from "@/components/tree/Nodes/user_tree_node_types";

export class ClassListNode extends TreeNode {

    alreadyFetched = false;

    constructor(parent = null, children = []) {
        super(_.uniqueId('ClassListNode'), 'Classes', CLASS_LIST_NODE_TYPE, parent)
        this.children = children
        this.icon = 'mdi-school'
    }

    async fetchChildren() {
        if (!this.alreadyFetched) {
            const nodeType = this.parentNode.type;
            const parentObj = this.parentNode[nodeType];
            if (parentObj && _.isEmpty(parentObj.classes)) {
                const {data, status} = await boAxios.get(`/${nodeType}/${parentObj.id}/classes/list`)
                if (status !== 200) {
                    return;
                }
                parentObj.classes = data.items
                this.alreadyFetched=true;
            }
            parentObj.classes.forEach(clazz => {
                this.children.push(new ClassNode(clazz, this))
            })
            if (_.isEmpty(parentObj.classes)) {
                this.children = undefined;
                this.name = 'No Classes'
                this.icon = 'mdi-school-outline'
            }
        }
        return this.children;
    }

    getObject() {
        return this.children.map(node => node.getObject())
    }

    refresh() {
        super.refresh();
        this.alreadyFetched = false;
        this.fetchChildren()
    }
}
