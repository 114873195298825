<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Reset User Password
        </v-card-title>
        <v-card-text v-on="error">{{error}}</v-card-text>

        <v-form class="pa-5" v-if="item">
              <v-text-field
                  v-model="item.id"
                  label="ID"
                  disabled
              ></v-text-field>
              <v-text-field
                  v-model="item.password"
                  label="Password"
              ></v-text-field>
              <v-text-field
                  v-model="item.confirmPassword"
                  label="Confirm Password"
              ></v-text-field>
              <v-btn
                color="darken-1"
                text
                @click="onGenerate"
              >
                Generate Password
              </v-btn>
              <v-checkbox v-model="item.mustReset" :label="`Force Update Password`"></v-checkbox>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="darken-1"
              text
              @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="onOk"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {

  name: "ResetPasswordDialog",
  data() {
    return {
      dialog: false,
      item: null,
      displayName: '',
      error: ''
    }
  },
  methods: {
    show(displayName, item) {
      this.displayName = displayName;
      this.item = item;
      this.item.mustReset = true;
      this.dialog = true;
    },
    onCancel() {
      this.dialog=false;
    },
    onOk() {
      if (this.validatePassword()) {
        this.dialog=false;
        this.$emit('reset-password-dialog-confirmation', this.item)
      }
    },
    onGenerate() {
      const pass = Math.random().toString(36).slice(-8);
      this.item = {...this.item, password: pass, confirmPassword: pass };
    },
    validatePassword() {

      this.error = '';

      if (!this.item.password) {
        this.showError('Must supply password');
        return false;
      }

      if (!this.item.confirmPassword) {
        this.showError('Must confirm password');
        return false;
      }

      if (this.item.confirmPassword !== this.item.password) {
        this.showError('Passwords don\'t match');
        return false;
      }

      return true;


    },
    showError(message) {
      this.error = message;
      setTimeout(() => {
        this.error = '';
      }, 3000);
    }
  }
}
</script>

<style scoped>

</style>
