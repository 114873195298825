<template>
    <span>
        <v-autocomplete
			class="search-container mx-4"
            v-model="resultItems"
            :items="dataItems"
            :search-input.sync="search"
            chips
            :clearable="isMultiple"
            deletable-chips
			color="blue"
            :label="label"
			:placeholder="placeholder"
            :item-text="itemText"
            :item-value="itemValue"
            :multiple="isMultiple"
            :disabled="disabled"
			:error="error"
			:error-messages="errorMessages"
			@change="handleChangeSearch"
        >
            <template v-slot:item="data">
				<v-list class="lighten-3">
					<v-list-item class="search-list-item">
						<v-list-item-title>{{ data.item.name }}</v-list-item-title>
					</v-list-item>
					<v-list-item class="search-list-subitem" v-if="itemType === 'school'">
						<v-list-item-icon class="ma-0 mr-1">
							<v-icon color="grey darken-2">{{ itemIcon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-subtitle>{{ data.item.formatted_address_lines }}</v-list-item-subtitle>
					</v-list-item>
				</v-list>
			</template>
        </v-autocomplete>
    </span>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    label: {
      type: String,
      default: 'Schools'
    },
	placeholder: {
      type: String,
      default: 'Search for a school...'
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    itemIcon: {
      type: String,
      default: 'mdi-map-marker'
    },
    isMultiple: {
      type: Boolean,
      default: true
    },
    itemType: {
      type: String,
      default: 'school'
    },
    disabled: Boolean,
    searchList: {
      type: Function,
      default: () => { return [] },
    },
    selectedItem: Object,
	error: Boolean,
	errorMessages: {
      type: Array,
      default: () => []
    },
	onSelected: {
      type: Function,
      default: (val) => { console.log(val) }
    }
  },
  data() {
    return {
      isLoading: false,
      dataItems: [],
      resultItems: null,
      search: null
    }
  },
  watch: {
    search() {
      if (this.isLoading) return
      this.isLoading = true
      this.debouncedGetDataFromApi()
    },
    selectedItem(newItem, oldItem) {
      if (newItem) {
		if (!this.resultItems || newItem.id !== oldItem?.id) {
			const filter = newItem[this.itemText]
			this.resultItems = newItem[this.itemValue]
			this.debouncedGetDataFromApi({
				filter,
				data: newItem
			})
		}
      } else {
		this.resultItems = null
		this.search = null
		}
    }
  },
  created() {
    this.debouncedGetDataFromApi = _.debounce(function (selectedInfo) {
      this.getDataFromApi(selectedInfo)
    }, 500, {leading: false})
    this.getDataFromApi()
  },
  methods: {
	isFetchDataAvailable() {
		// return fetch available status by checking current result or search
		// true: if result items are not existing in current list or new search is coming
		let isResultFound = false
		if (this.resultItems) {
			let resultMatchCount = 0
			for (let dataItem of this.dataItems) {
				if (this.isMultiple) {
					this.resultItems.forEach(resultItem => {
						if (dataItem[this.itemValue] === resultItem[this.itemValue]) {
							resultMatchCount++
						}
					})
					if (resultMatchCount === this.resultItems.length) {
						isResultFound = true
						break
					}
				} else if (dataItem[this.itemValue] === this.resultItems) {
					isResultFound = true
					break
				}
			}
		}
		return !isResultFound || !!this.search
	},
    async getDataFromApi(selectedInfo) {
	if (!this.isFetchDataAvailable()) return

	const searchItems = await this.searchList(this.search || selectedInfo?.filter)

	if (selectedInfo) {
		const selectedItemValue = selectedInfo.data[this.itemValue]
		const selectedItem = searchItems.find(item => item[this.itemValue] === selectedItemValue)

		if (selectedItem) {
			this.dataItems = searchItems
		} else {
			this.dataItems = [selectedInfo.data]
		}
	} else {
		this.dataItems = searchItems
	}

	this.isLoading = false
    },
	handleChangeSearch() {
		this.onSelected(this.resultItems)
		if (!this.resultItems && !this.search) {
			this.debouncedGetDataFromApi()
		}
	}
  }
}
</script>

<style lang="scss" scoped>
.search-container {
  position: relative;
}
.search-list-item {
  min-height: 30px;
}
.search-list-subitem {
  min-height: auto;
}
</style>