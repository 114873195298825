<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        content-class="delete-dialog"
        persistent
        max-width="420"
    >
      <v-card class="pa-0 ma-0">
        <v-card-title class="headline">
          Warning!
        </v-card-title>
        <v-card-text class="pr-10 delete-confirm-text">Are You Sure You Want To Delete this {{targetDisplayName}}?</v-card-text>
        <v-container class="delete-dialog-content">
        <v-row
            justify="center"
            align="center"
            class="pb-6"
        >
          <v-chip large label color="red" text-color="white" class="text-h5 mx-auto">{{displayName}}</v-chip>
        </v-row>
        <v-row>
          <v-text-field autofocus v-model="deletemeInput" class="mx-auto px-5"></v-text-field>
        </v-row>
        </v-container>
        <v-card-text>Type 'delete' and click Yes</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="darken-1"
              text
              @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
              :disabled="!okEnabled"
              color="red darken-1"
              text
              @click="onOk"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {

  name: "DeleteDialog",
  props: {
    target: {
      type: String,
      default: 'User'
    }
  },
  data() {
    return {
      dialog: false,
      item: null,
      displayName: '',
      deletemeInput: '',
      okEnabled: false
    }
  },
  computed: {
    targetDisplayName() {
      return this.target.toLowerCase()
    }    
  },
  watch: {
    deletemeInput(newVal) {
      console.log('hee')
      this.okEnabled = (newVal === 'delete')
    }
  },
  methods: {
    show(displayName, item) {
      this.displayName = displayName;
      this.item = item;
      this.dialog = true;
      this.okEnabled = false;
      this.deletemeInput = '';
    },
    onCancel() {
      this.dialog=false;
    },
    onOk() {
      if (this.deletemeInput === 'delete') {
        this.dialog=false;
        this.$emit('delete-dialog-confirmation', this.item)
      }
    }
  }
}
</script>

<style>
.delete-dialog {
  overflow-x: hidden;
}
</style>

<style scoped>
.delete-confirm-text {
  padding-right: 10px !important;
  width: calc(100% - 34px);
}

.delete-dialog-content {
  width: calc(100% - 24px);
}
</style>
